/* ============
 * Global Module
 * ============
 */

import actions from './actions';
import getters from './getters';
import mutations from './mutations';

let determineSection;
const getItem = JSON.parse(sessionStorage.getItem('lastPageViewed'));
if (sessionStorage.getItem('lastPageViewed') !== null && Object.keys(sessionStorage.getItem('lastPageViewed')).length >= 1) {
  if (Object.hasOwn(getItem, 'section')) {
    determineSection = getItem.section.charAt(0).toUpperCase() + getItem.section.slice(1);
  }
} else {
  determineSection = 'Driver';
}

// initial state
const state = () => ({
  driverOrMechanicData: determineSection,
  showModal: false,
});

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
