  <template>
  <div>
    <link
      rel="stylesheet"
      href="//fonts.googleapis.com/css?family=Roboto:400,500,700,400italic|Material+Icons"
    />
    <Login v-if="route === 'Login'" />
    <callback v-if="route === 'Callback'" />
    <LoadingSpinner v-if="route === 'Loading Spinner'"
      :loading="true"
      :color="'#70A300'"
      :size="'200px'"
      :borderWidth="'14px'"/>
    <main-wrapper v-if="route === 'Trends'" :drawer-items="marketDrawer" />
    <main-wrapper v-if="route === 'Locations'" :drawer-items="marketLocation" />
    <main-wrapper v-if="route === 'Job Postings Driver'" :drawer-items="competitionDrawer" />
    <main-wrapper v-if="route === 'Maps'" :drawer-items="marketLocation" />
    <main-wrapper v-if="route === 'Job Postings Mechanic'" :drawer-items="competitionDrawer" />
    <main-wrapper v-if="route === 'Hourly Wages'" :drawer-items="wagesDrawer" />
    <main-wrapper v-if="route === 'Annual Wages'" :drawer-items="wagesDrawer" />
    <NotFound v-if="route === 'Not Found'" />
    <main-wrapper v-if="route === 'Admin'" :drawer-items="[]"/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Login from './components/Login.vue';
import Callback from './components/Callback.vue';
import NotFound from './components/Pages/NotFound.vue';
import Admin from './components/Pages/Admin.vue';
import MainWrapper from './components/Layout/MainWrapper.vue';
import Domo from './components/Domo.vue';
import LoadingSpinner from './components/StyledComponents/LoadingSpinner.vue';

export default {
  name: 'App',
  components: {
    Login,
    MainWrapper,
    Callback,
    NotFound,
    // eslint-disable-next-line vue/no-unused-components
    Admin,
    // eslint-disable-next-line vue/no-unused-components
    Domo,
    LoadingSpinner,
  },
  data() {
    return {
      activeRoutes: {
        '/login': 'Login',
        '/callback': 'Callback',
        '/driver/market/trends': 'Trends',
        '/driver/locations/overview': 'Locations',
        '/admin': 'Admin',
        '/driver/competition/job-postings': 'Job Postings Driver',
        '/driver/locations/map': 'Maps',
        '/mechanic/competition/job-postings': 'Job Postings Mechanic',
        '/mechanic/reported-wages/hourly': 'Hourly Wages',
        '/mechanic/reported-wages/annual': 'Annual Wages',
        '/': 'Loading spinner',
      },
      marketDrawer: [
        {
          icon: 'trending_up',
          title: 'Trends',
          url: '/driver/market/trends',
          linkId: 'trends',
        },
      ],
      marketLocation: [
        {
          icon: 'place',
          title: 'Locations',
          url: '/driver/locations/overview',
          linkId: 'locations',
        },
        {
          icon: 'travel_explore',
          title: 'Maps',
          url: '/driver/locations/map',
          linkId: 'maps',
        },
      ],
    };
  },
  channels: {
    NotificationChannel: {
      connected() {
        this.$store.dispatch('notifications/getUserNotifications');
      },
      received(data) {
        this.$store.commit('notifications/addNewNotification', data);
      },
      rejected() {},
      disconnected() {},
    },
  },
  mounted() {
    if (this.$store.state.auth.userInfo.roles.length !== 0) {
      this.$cable.subscribe({
        channel: 'NotificationChannel',
      });
    } else {
      this.$store.watch(
        (state) => state.auth.userInfo.roles,
        (newRoles) => {
          if (newRoles.length > 0) {
            this.$cable.subscribe({
              channel: 'NotificationChannel',
            });
          }
        },
      );
    }
  },
  computed: {
    competitionDrawer() {
      const items = [{
        icon: 'article',
        title: `Job Postings ${this.competitionPageSelected}`,
        url: `/${this.competitionPageSelected.toLowerCase()}/competition/job-postings`,
        linkId: 'job postings',
      }];
      return items;
    },
    wagesDrawer() {
      return [
        {
          icon: 'schedule',
          title: 'Hourly Wages',
          url: '/mechanic/reported-wages/hourly',
          linkId: 'wages',
        },
        {
          icon: 'calendar_month',
          title: 'Annual Wages',
          url: '/mechanic/reported-wages/annual',
          linkId: 'wages',
        },
      ];
    },
    ...mapGetters({
      competitionPageSelected: `global/getDriverOrMechanicData`,
    }),
    route() {
      if (this.activeRoutes[this.$route.fullPath]) {
        return this.activeRoutes[this.$route.fullPath];
      }
      return 'Not Found';
    },
    notificationModalOpen() {
      return this.$store.state.notifications.modalOpen;
    },
  },
};
</script>
