/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable global-require */
/* eslint-disable no-else-return */

import Vue from 'vue';
import Router from 'vue-router';
import * as Sentry from '@sentry/vue';
import Trends from '../components/Market/Trends.vue';
import RecruitingDifficulty from '../components/Locations/Maps/RecruitingDifficulty.vue';
import Locations from '../components/Locations/Locations.vue';
import JobPostings from '../components/Competition/JobPostings.vue';
import Login from '../components/Login.vue';
import Callback from '../components/Callback.vue';
import Admin from '../components/Pages/Admin.vue';
import NotFound from '../components/Pages/NotFound.vue';
import Domo from '../components/Domo.vue';
import Wages from '../components/Wages/Wages.vue';
import AnnualWages from '../components/Wages/AnnualWages.vue';

const store = require('../store/index');

Vue.use(Router);

function determineAccessAndReroute(lastPageViewed, next) {
  if (lastPageViewed.section === 'driver') {
    if (store.default.getters['auth/getUserRoles'].includes('StrategizeDriver')) {
      store.default.commit('global/setDriverOrMechanicData', 'Driver');
      return next({ name: lastPageViewed.linkId });
    } else {
      store.default.commit('auth/setRestrictPageAccess', true);
      store.default.commit('auth/setRestrictedPageAccessType', 'Driver');
      store.default.commit('global/setDriverOrMechanicData', 'Mechanic');
      return next({ name: 'Job Postings Mechanic' });
    }
  }
  if (lastPageViewed.section === 'mechanic') {
    if (store.default.getters['auth/getUserRoles'].includes('StrategizeMechanic')) {
      store.default.commit('global/setDriverOrMechanicData', 'Mechanic');
      return next({ name: lastPageViewed.linkId });
    } else {
      store.default.commit('auth/setRestrictPageAccess', true);
      store.default.commit('auth/setRestrictedPageAccessType', 'Mechanic');
      store.default.commit('global/setDriverOrMechanicData', 'Driver');
      return next({ name: 'Job Postings Driver' });
    }
  }
}

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/login',
      name: 'Login',
      component: Login,
      beforeEnter: async (to, from) => {
        const codeChallenge = await store.default.dispatch('auth/getChallenge');
        localStorage.setItem('code_verifier', codeChallenge.code_verifier);
        const url = await store.default.dispatch('auth/loginUrl');
        const pkceUrl = `${url}&code_challenge=${codeChallenge.code_challenge}&code_challenge_method=S256`;
        window.location.href = pkceUrl;
      },
    },
    {
      path: '/callback',
      name: 'Callback',
      component: Callback,
      beforeEnter: async (to, from, next) => {
        const { query: { code } } = to;
        const verifier = localStorage.getItem('code_verifier');
        const data = {
          auth_code: code,
          code_verifier: verifier,
        };
        await store.default.dispatch('auth/ssoLogin', data);
        localStorage.removeItem('code_verifier');
        const lastPageViewed = JSON.parse(sessionStorage.getItem('lastPageViewed'));
        if (lastPageViewed) {
          determineAccessAndReroute(lastPageViewed, next);
        } else {
          // eslint-disable-next-line no-lonely-if
          if (store.default.getters['auth/getUserRoles'].includes('StrategizeMechanic')) {
            return next({ name: 'Job Postings Mechanic' });
          } else {
            return next({ name: 'Job Postings Driver' });
          }
        }
      },
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: '/driver/market/trends',
      name: 'Trends',
      component: Trends,
      meta: {
        requiresAuth: true,
        requiresDriver: true,
      },
    },
    {
      path: '/driver/locations/overview',
      name: 'Locations',
      component: Locations,
      meta: {
        requiresAuth: true,
        requiresDriver: true,
      },
    },
    {
      path: '/driver/locations/map',
      name: 'Maps',
      component: RecruitingDifficulty,
      meta: {
        requiresAuth: true,
        requiresDriver: true,
      },
    },
    {
      path: '/driver/competition/job-postings',
      name: 'Job Postings Driver',
      component: JobPostings,
      meta: {
        requiresAuth: true,
        requiresDriver: true,
      },
    },
    {
      path: '/mechanic/competition/job-postings',
      name: 'Job Postings Mechanic',
      component: Domo,
      meta: {
        requiresAuth: true,
        requiresMechanic: true,
      },
    },
    {
      path: '/admin',
      name: 'Admin',
      component: Admin,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/mechanic/reported-wages/hourly',
      name: 'Hourly Wages',
      component: Wages,
      meta: {
        requiresAuth: true,
        requiresMechanic: true,
      },
    },
    {
      path: '/mechanic/reported-wages/annual',
      name: 'Annual Wages',
      component: AnnualWages,
      meta: {
        requiresAuth: true,
        requiresMechanic: true,
      },
    },
    {
      path: '*',
      component: NotFound,
    },
  ],
});

router.beforeEach((to, from, next) => {
  if (process.env.NODE_ENV === 'production') {
    Sentry.configureScope((scope) => scope.setTransactionName(to?.meta?.label));
  }

  if (window.heap) {
    window.heap.track(to.name, { path: to.path });
  }

  if (to.name === 'Login' || to.name === 'Callback' || to.name === 'Admin') {
    return next();
  }

  if (store.default.getters['auth/getUserRoles'].length === 0) {
    return store.default.dispatch('auth/checkUser').then((response) => {
      if (response === 404) {
        return next({ name: 'Login' });
      } else {
        if (to.fullPath === '/') {
          if (store.default.getters['auth/getUserRoles'].includes('StrategizeMechanic')) {
            return next({ name: 'Job Postings Mechanic' });
          } else {
            return next({ name: 'Job Postings Driver' });
          }
        }
        if (!store.default.getters['auth/getUserRoles'].includes('StrategizeMechanic') && to.meta.requiresMechanic) {
          store.default.commit('auth/setRestrictPageAccess', true);
          store.default.commit('auth/setRestrictedPageAccessType', 'Mechanic');
          store.default.commit('global/setDriverOrMechanicData', 'Driver');
          return next({ name: 'Job Postings Driver' });
        } else if (!store.default.getters['auth/getUserRoles'].includes('StrategizeDriver') && to.meta.requiresDriver) {
          store.default.commit('auth/setRestrictPageAccess', true);
          store.default.commit('auth/setRestrictedPageAccessType', 'Driver');
          store.default.commit('global/setDriverOrMechanicData', 'Mechanic');
          return next({ name: 'Job Postings Mechanic' });
        } else {
          return next();
        }
      }
    });
  }

  if (to.meta.requiresDriver && store.default.getters['auth/getUserRoles'].length !== 0) {
    if (store.default.getters['auth/getUserRoles'].includes('StrategizeDriver')) {
      store.default.commit('global/setDriverOrMechanicData', 'Driver');
      return next();
    } else {
      store.default.commit('auth/setRestrictPageAccess', true);
      store.default.commit('auth/setRestrictedPageAccessType', 'Driver');
      store.default.commit('global/setDriverOrMechanicData', 'Mechanic');
      return next({ name: 'Job Postings Mechanic' });
    }
  }

  if (to.meta.requiresMechanic && store.default.getters['auth/getUserRoles'].length !== 0) {
    if (store.default.getters['auth/getUserRoles'].includes('StrategizeMechanic')) {
      store.default.commit('global/setDriverOrMechanicData', 'Mechanic');
      return next();
    } else {
      store.default.commit('auth/setRestrictPageAccess', true);
      store.default.commit('auth/setRestrictedPageAccessType', 'Mechanic');
      store.default.commit('global/setDriverOrMechanicData', 'Driver');
      return next({ name: 'Job Postings Driver' });
    }
  }
  return next();
});

export default router;
