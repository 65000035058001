import detectMobileDevice from '../../../helpers/detect-user-agent';

const sidebarItemToNavbarItemMap = {
  Maps: 'locations',
  Locations: 'locations',
  Trends: 'market',
  'Job Postings Driver': 'Job Postings Driver',
  'Job Postings Mechanic': 'Job Postings Mechanic',
  'Hourly Wages': 'Hourly Wages',
  'Annual Wages': 'Annual Wages',
};

export default {
  // eslint-disable-next-line
  removeActiveNavItem({}, data) {
    const lastPageViewed = JSON.parse(sessionStorage.getItem('lastPageViewed'));
    if (!detectMobileDevice()) {
      if (data.title === 'market') {
        if (document.getElementById('locations') !== null || document.getElementById('Maps') !== null) {
          document.getElementById('locations').classList.remove('is-active');
        }

        if (document.getElementById('trends') !== null) {
          document.getElementById('trends').classList.remove('is-active');
        }
        if (document.getElementById('competition') !== null) {
          document.getElementById('competition').classList.remove('is-active');
        }
      } else if (data.title === 'locations') {
        if (document.getElementById('competition') !== null) {
          document.getElementById('competition').classList.remove('is-active');
        }

        if (document.getElementById('trends') !== null) {
          document.getElementById('trends').classList.remove('is-active');
        }
      } else if (data.title === 'driver') {
        if (document.getElementById('locations') !== null) {
          document.getElementById('locations').classList.remove('is-active');
        }

        if (document.getElementById('trends') !== null) {
          document.getElementById('trends').classList.remove('is-active');
        }
      } else if (data.linkId === 'Job Postings Mechanic') {
        if (document.getElementById('Hourly Wages') !== null) {
          document.getElementById('Hourly Wages').classList.remove('is-active');
        }
      }
    } else {
      // eslint-disable-next-line
      if (document.getElementById(lastPageViewed.mobileID)) {
        document.getElementById(lastPageViewed.mobileID).classList.remove('mobile-nav-active');
      }
    }
  },

  async addPageLinkHighlightAndNavLinkBorder({ dispatch }, payload) {
    if (payload !== null) {
      const data = payload.link;
      // setting page link to background color green as active
      const linkId = sidebarItemToNavbarItemMap[data.linkId];
      const pageLinkElement = document.getElementById(linkId);

      if (pageLinkElement !== null) {
        pageLinkElement.classList.add('is-active');
      }
      // remove all bottom border for all links in navbar
      await dispatch('removeActiveNavItem', data);
      if (!detectMobileDevice()) {
        // add the bottom border to selected link in navbar
        const element = document.getElementById(data.title);
        if (element !== null && element !== undefined) {
          element.classList.add('is-active');
        }
      } else {
        // Set the mobile nav settings
        const mobileElement = document.getElementById(data.mobileID);
        mobileElement.classList.add('mobile-nav-active');
      }
    }
  },
};
