<template>
  <div>
    <v-dialog
      v-model="showModal"
      max-width="890"
      persistent
      content-class="location-modal-card"
    >
      <div
        class="p-4">
        <div v-if="dataLoading" class="md-layout-item">
          <LoadingSpinner
            class="location-row-loading-spinner"
            :borderWdith="'4px'"
            :color="'#70A300'"
            :loading="dataLoading"
            :size="'75px'"
          />
        </div>
        <div v-else>
          <v-card-title class="d-flex justify-center align-center">
            <div class="d-flex flex-grow-1 align-center">
              {{ columnHeaders[0] }}
            </div>
            <span v-if="hoverOverCopyButton" class="location-modal-copy-helper-text mt-5">
              copy the entire table to your clipboard
            </span>
            <v-spacer></v-spacer>
            <v-btn
              elevation="2"
              class="green close-button"
              @click="$emit('closeModal')"
            >
              <v-icon :style="'color: white;'">mdi-close</v-icon>
            </v-btn>
            <v-btn
              elevation="2"
              class="grey copy-button"
              @mouseover="hoverOverCopyButton = true"
              @mouseleave="hoverOverCopyButton = false"
              @click="copyToClipboard('location-modal-table')"
            >
              <v-icon :style="'color: white;'">mdi-clipboard-text</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-card>
              <v-data-table
                id="location-modal-table"
                class="location-modal-table"
                :headers="fields"
                :items="locationRowModalData"
                :custom-sort="sortData"
                :sort-by="columnValues[0]"
                :sort-desc="true"
                :fixed-header="true"
                :disable-pagination="true"
                :hide-default-footer="true"
                no-data-text="No Data to Display"
              ></v-data-table>
            </v-card>
          </v-card-text>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import LoadingSpinner from '../LoadingSpinner.vue';

export default {
  name: 'LocationRowModal',
  components: {
    LoadingSpinner,
  },
  props: {
    showModal: { type: Boolean, required: true },
    columnHeaders: { type: Array, required: true },
    columnValues: { type: Array, required: true },
    locationRowModalData: { type: Array, required: true },
    rowClicked: { type: String, required: true },
  },
  data() {
    return {
      data: [],
      hoverOverCopyButton: false,
      fields: [],
      locationField: { value: 'location_name', sortable: true, text: 'Location Name' },
      selectedLocationRowIndex: null,
      sortValues: [],
    };
  },
  async beforeMount() {
    this.data = this.$props.locationRowModalData;

    let sortableFields = await this.$props.columnValues.map((columnValue, index) => ({
      text: this.$props.columnHeaders[index],
      value: columnValue,
      sortable: true,
    }));

    sortableFields = sortableFields.filter((item) => item.text !== null);

    this.fields = [this.locationField, ...sortableFields];
  },
  mounted() {
    setTimeout(() => {
      this.sortChanged();
    }, 10);
  },
  methods: {
    sortData(items, sortBy, sortDesc) {
      if (!sortBy.length) {
        // When defaut order is applied, the soryBy length is 0.
        setTimeout(() => {
          this.sortChanged();
        }, 10);

        return items;
      }

      const [sortKey] = sortBy;
      const [desc] = sortDesc;

      const parseValue = (value) => {
        if (value === null) return -Infinity; // Treat null as less than any number
        if (typeof value === 'string') {
          if (value.endsWith('%')) {
            return parseFloat(value.replace('%', ''));
            // eslint-disable-next-line
          } else if (!isNaN(parseFloat(value))) {
            return parseFloat(value);
          }
        }

        return value;
      };

      const compareFn = (a, b) => {
        const aValue = parseValue(a[sortKey]);
        const bValue = parseValue(b[sortKey]);

        if (aValue < bValue) return desc ? 1 : -1;
        if (aValue > bValue) return desc ? -1 : 1;
        return 0;
      };

      // highlight selected location after changing sort order
      setTimeout(() => {
        this.sortChanged();
      }, 10);

      return items.slice().sort(compareFn);
    },
    async sortChanged() {
      if (this.selectedLocationRowIndex !== null) {
        await this.clearSelectedLocationPreviousRow();
      }
      const rows = document.querySelector('#location-modal-table table');
      if (rows !== null) {
        const lastLocation = sessionStorage.getItem('lastLocationSearched');
        const searchedLocation = JSON.parse(lastLocation);
        this.selectedLocationRowIndex = null;

        for (let i = 1; i < rows.rows.length; i += 1) {
          let location = rows.rows[i];
          location = location.getElementsByTagName('td');
          location = location[0].innerHTML;
          if (location === searchedLocation.location_name) {
            this.selectedLocationRowIndex = i;
            rows.rows[i].classList.add('selected-row');
            const topOfTable = 6;
            const bottomOfTable = rows.rows.length - 7;
            if (rows.rows.length - i > topOfTable && rows.rows.length - i < bottomOfTable) {
              rows.rows[i - 4].scrollIntoView(true);
            } else if (i <= topOfTable) {
              rows.rows[i - 1].scrollIntoView(true);
            } else if (i >= bottomOfTable) {
              rows.rows[i].scrollIntoView(true);
            }
          }
        }
      }
    },
    clearSelectedLocationPreviousRow() {
      const rows = document.querySelectorAll('#location-modal-table table')[0];
      rows.rows[this.selectedLocationRowIndex].classList.remove('selected-row');
      this.selectedLocationRowIndex = null;
    },
    async copyToClipboard() {
      await this.highlightRows();
      document.execCommand('copy');
      this.$toast.open({
        message: 'Copied',
        type: 'success',
        duration: 3000,
      });
    },
    highlightRows() {
      const elemToSelect = document.querySelectorAll('#location-modal-table table')[0];
      const selection = window.getSelection();
      const rangeToSelect = document.createRange();
      rangeToSelect.selectNodeContents(elemToSelect);
      selection.removeAllRanges();
      selection.addRange(rangeToSelect);
    },
  },
  computed: {
    dataLoading() {
      return this.$store.state.locations.locationRowLoading;
    },
  },
};
</script>

<style>
.location-row-loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
}
.green {
  grid-column: 4;
  grid-row: 1;
  background-color: #70A300;
  display: flex;
  justify-content: center;
  align-items: center;
}
.location-modal-copy-helper-text {
  font-size: 12px;
  font-weight: 100;
}
.selected-row {
  font-weight: bold;
}
.sr-only {
  display: none;
}
</style>
<style lang="scss" scoped>
.close-button {
  margin: 1% !important;
}
.copy-button {
  margin: 1% !important;
}
.location-modal-table {
  max-height: 500px;
  overflow-y: auto;
}
.v-card {
  padding: 0 2% 2% 2%;
}
</style>
